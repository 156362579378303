/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import DashBoardModel from '../../../../../models/gc/DashBoardModel'
import {getMonthName, LoadingSpinner} from '../../../../Utilities'

type Props = {
  className: string
  selectedYear: number
  selectedMonth: number
}

type TypeCommercials = {
  [label: string]: any
}

const SalesStatisticsOnThisMonth: React.FC<Props> = (props) => {
  const {className, selectedYear, selectedMonth} = props
  const [loading, setLoading] = useState(true)
  const [commercialsActivities, setCommercialsActivities] = useState<Array<TypeCommercials>>([])

  const fetchSectionCommercialsActivities = async (year: number, month: number) => {
    setLoading(true)
    let dataModel = new DashBoardModel()
    const {data} = await dataModel.getSectionCommercialsActivities(year, month)
    if (data.type === 'success') {
      setCommercialsActivities((prevState) => data.result)
    } else {
      setCommercialsActivities((prevState) => [])
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchSectionCommercialsActivities(selectedYear, selectedMonth)
  }, [selectedYear, selectedMonth])

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>
            {`Statistiques des commerciaux pour le mois de `}
            {`(${getMonthName(selectedMonth)} ${selectedYear})`}
          </span>
          <span className='text-muted mt-1 fw-bold fs-7'></span>
        </h3>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted'>
                <th className='min-w-200px'>Personne</th>
                <th className='min-w-100px text-center'>Quota des ventes</th>
                <th className='min-w-200px'>Progression</th>
              </tr>
            </thead>
            {/* end::Table head */}

            {/* begin::Table body */}
            <tbody>
              {loading ? (
                <tr className='text-center'>
                  <td colSpan={3}>
                    <LoadingSpinner parentClass='pt-5' iconClass='fs-1' />
                  </td>
                </tr>
              ) : (
                <>
                  {commercialsActivities.length > 0 ? (
                    commercialsActivities.map((commercialsActivity, commercialsActivityIndex) => {
                      return (
                        <tr key={commercialsActivityIndex + '_commercialsActivity'}>
                          <td>
                            <div className='d-flex align-items-center'>
                              <div className='symbol symbol-45px me-5'>
                                <img src={commercialsActivity?.person_photo_pers} alt='' />
                              </div>
                              <div className='d-flex justify-content-start flex-column'>
                                <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                                  {commercialsActivity?.person_nom}
                                </a>
                                <span className='fw-bold text-primary d-block fs-7'>
                                  {commercialsActivity?.person_fonction}
                                </span>
                              </div>
                            </div>
                          </td>

                          <td className='text-center'>
                            {commercialsActivity?.count_order} / {commercialsActivity?.count_quote}
                          </td>

                          <td className='text-end'>
                            <div className='d-flex flex-column w-100 me-2'>
                              <div className='d-flex flex-stack mb-2'>
                                <span className='text-info me-2 fs-7 fw-bold'>
                                  {commercialsActivity?.progress} %
                                </span>
                              </div>

                              <div className='progress h-6px w-100'>
                                {commercialsActivity?.progress < 50 ? (
                                  <div
                                    className='progress-bar bg-danger'
                                    role='progressbar'
                                    style={{width: commercialsActivity?.progress + '%'}}
                                  ></div>
                                ) : (
                                  <div
                                    className='progress-bar bg-success'
                                    role='progressbar'
                                    style={{width: commercialsActivity?.progress + '%'}}
                                  ></div>
                                )}
                              </div>
                            </div>
                          </td>
                        </tr>
                      )
                    })
                  ) : (
                    <>
                      <tr className='text-center'>
                        <td colSpan={3}>
                          <p className='fw-bolder text-muted'>Pas d'informations ...</p>
                        </td>
                      </tr>
                    </>
                  )}
                </>
              )}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {SalesStatisticsOnThisMonth}
