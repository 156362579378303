import Model from '../Model'

class PlanningModel extends Model {
  private prefix = 'executions/plannings'

  getWithParams(path: any, params: any) {
    path = this.prefix + path
    return this.withParams(path, params)
  }

  getAll(params: any) {
    const path = this.prefix
    return this.withParams(path, params)
  }

  add(data: {}) {
    return this.post(this.prefix + `/save-add`, data)
  }

  find(id: bigint) {
    return this.get(this.prefix + `/find/${id}`)
  }

  update(id: bigint, data: {}) {
    return this.post(this.prefix + `/save-edit/${id}`, data)
  }

  delete(id: bigint) {
    return this.get(this.prefix + `/delete/${id}`)
  }

  getQuota(id: bigint) {
    return this.get(this.prefix + `/quota/${id}`)
  }

  saveCommentTodoPlanning(id: bigint, data: {}) {
    return this.post(this.prefix + `/save-comment-todo-planning/${id}`, data)
  }

  saveIsDoPlanning(id: bigint, status: any) {
    return this.get(this.prefix + `/save-is-do-planning/${id}/${status}`)
  }
}

export default PlanningModel
