import Model from '../Model'
import {formatDate} from '../../app/Utilities'

class DashBoardModel extends Model {
  private prefix = 'commercials/dashboard'

  getWithParams(path: any, params: any) {
    path = this.prefix + path
    return this.withParams(path, params)
  }

  getSectionYearObjective(year: number) {
    const path = '/section-year-objective/' + year
    return this.getWithParams(path, {})
  }

  getSectionCustomersCount(year: number) {
    const path = '/section-customers-count/' + year
    return this.getWithParams(path, {})
  }

  getSectionOrdersLastActivities(year: number, month: number, day: number) {
    const date = formatDate(year, month, day)
    const path = '/section-orders-last-activities/' + date
    return this.getWithParams(path, {})
  }

  getSectionInvoicesLastActivities(year: number, month: number, day: number) {
    const date = formatDate(year, month, day)
    const path = '/section-invoices-last-activities/' + date
    return this.getWithParams(path, {})
  }

  getSectionGraphQuotes(year: number) {
    const path = '/section-graph-quotes/' + year
    return this.getWithParams(path, {})
  }

  getSectionGraphOrders(year: number) {
    const path = '/section-graph-orders/' + year
    return this.getWithParams(path, {})
  }

  getSectionTotalInvoices(year: number) {
    const path = '/section-total-invoices/' + year
    return this.getWithParams(path, {})
  }

  getSectionGraphInvoices(year: number) {
    const path = '/section-graph-invoices/' + year
    return this.getWithParams(path, {})
  }

  getSectionTotalRecoveries(year: number) {
    const path = '/section-total-recoveries/' + year
    return this.getWithParams(path, {})
  }

  getSectionGraphRecoveries(year: number) {
    const path = '/section-graph-recoveries/' + year
    return this.getWithParams(path, {})
  }

  getSectionCommercialsActivities(year: number, month: number) {
    const path = '/section-commercials-activities/' + year + '/' + month
    return this.getWithParams(path, {})
  }
}

export default DashBoardModel
