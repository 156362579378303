/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import DashBoardModel from '../../../../../models/gc/DashBoardModel'
import {CountNewCustomersHasQuoteAndOrderInCurrentYear} from './CountNewCustomersHasQuoteAndOrderInCurrentYear'
import {CountLeadsHasQuoteNotOrderInCurrentYear} from './CountLeadsHasQuoteNotOrderInCurrentYear'
import {CountActifCustomersHasOrderInPreviousAndCurrentYear} from './CountActifCustomersHasOrderInPreviousAndCurrentYear'
import {CountAllCustomers} from './CountAllCustomers'

type Props = {
  className: string
  bgHeaderColor: string
  selectedYear: number
}

const initSectionCustomersCount = {
  countProspects: 0,
  countActivesCustomers: 0,
  countNewCustomers: 0,
}

const SectionCustomersCountView: React.FC<Props> = (props) => {
  const {className, bgHeaderColor, selectedYear} = props

  // status count
  const [loadingStatus, setLoadingStatus] = useState(true)
  const [status, setStatus] = useState(initSectionCustomersCount)

  const fetchSectionCustomersCount = async (year: number) => {
    setLoadingStatus(true)
    let dataModel = new DashBoardModel()
    const {data} = await dataModel.getSectionCustomersCount(year)
    if (data.type === 'success') {
      setStatus((prevState) => {
        return {
          countNewCustomers: data.result.countNewCustomers,
          countProspects: data.result.countProspects,
          countActivesCustomers: data.result.countActivesCustomers,
        }
      })
    } else {
      setStatus(initSectionCustomersCount)
    }
    setLoadingStatus(false)
  }

  useEffect(() => {
    fetchSectionCustomersCount(selectedYear)
  }, [selectedYear])

  return (
    <>
      <div className={`card ${className}`}>
        {/* begin::Header */}
        <div className={`card-header border-0 w-100 py-5 mb-5 bg-${bgHeaderColor}`}></div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className='card-body p-0'>
          {/* begin::Chart */}
          {/* <div className={`mixed-widget-2-chart card-rounded-bottom bg-${bgHeaderColor}`}></div> */}
          {/* end::Chart */}

          {/* begin::Memos */}
          <div className='card-p mt-n20 position-relative'>
            {/* begin::Row */}
            <div className='row g-0'>
              <CountNewCustomersHasQuoteAndOrderInCurrentYear
                loading={loadingStatus}
                count={status?.countNewCustomers}
              />

              <CountLeadsHasQuoteNotOrderInCurrentYear
                loading={loadingStatus}
                count={status?.countProspects}
              />
            </div>
            {/* end::Row */}

            {/* begin::Row */}
            <div className='row g-0'>
              <CountActifCustomersHasOrderInPreviousAndCurrentYear
                loading={loadingStatus}
                count={status?.countActivesCustomers}
              />

              <CountAllCustomers loading={loadingStatus} count={0} />
            </div>
            {/* end::Row */}
          </div>
          {/* end::Memos */}
        </div>
        {/* end::Body */}
      </div>
    </>
  )
}

export {SectionCustomersCountView}
