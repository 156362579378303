/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {SectionCustomersCountView} from './components/status/SectionCustomersCountView'
import {LastOrdersActivitiesOnThisDay} from './components/activities/LastOrdersActivitiesOnThisDay'
import {LastInvoicesActivitiesOnThisDay} from './components/activities/LastInvoicesActivitiesOnThisDay'
import {GraphAmountQuotesByMonthInCurrentYear} from './components/graphs/GraphAmountQuotesByMonthInCurrentYear'
import {GraphAmountOrdersByMonthInCurrentYear} from './components/graphs/GraphAmountOrdersByMonthInCurrentYear'
import {GraphTurnoverByMonthInCurrentYear} from './components/graphs/GraphTurnoverByMonthInCurrentYear'
import {GraphRecoveriesByMonthInCurrentYear} from './components/graphs/GraphRecoveriesByMonthInCurrentYear'
import {SalesStatisticsOnThisMonth} from './components/lists/SalesStatisticsOnThisMonth'
import {ObjectiveYearProgressBar} from './components/status/ObjectiveYearProgressBar'
import DashBoardModel from '../../../models/gc/DashBoardModel'

const initSectionYearObjective = {
  totalInvoice: 0,
  yearObjective: 0,
  real_percent: 0,
  percent: 0,
}

type Props = {
  cdi: any
  selectedYear: number
  selectedMonth: number
  selectedDay: number
}

const DashboardView: FC<Props> = (props) => {
  const {selectedYear, selectedMonth, selectedDay} = props

  // objective
  const [loadingObjective, setLoadingObjective] = useState(true)
  const [objective, setObjective] = useState(initSectionYearObjective)

  const fetchSectionYearObjective = async (year: number) => {
    setLoadingObjective(true)
    let dataModel = new DashBoardModel()
    const {data} = await dataModel.getSectionYearObjective(year)
    if (data.type === 'success') {
      setObjective((prevState) => {
        return {
          totalInvoice: data.result.totalInvoice,
          yearObjective: data.result.yearObjective,
          real_percent: data.result.real_percent,
          percent: data.result.percent,
        }
      })
    } else {
      setObjective(initSectionYearObjective)
    }
    setLoadingObjective(false)
  }

  useEffect(() => {
    fetchSectionYearObjective(selectedYear)
  }, [selectedYear])

  return (
    <>
      {/* begin::Row */}
      <div className='row gy-5 g-xl-8 mb-3'>
        <div className='col-xxl-12'>
          <ObjectiveYearProgressBar loading={loadingObjective} objective={objective} />
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row gy-5 g-xl-8'>
        <div className='col-xxl-4'>
          <SectionCustomersCountView
            className='card-xl-stretch mb-5 mb-xl-8'
            bgHeaderColor={'danger'}
            selectedYear={selectedYear}
          />
        </div>

        <div className='col-xxl-4'>
          <LastOrdersActivitiesOnThisDay
            className='card-xxl-stretch mb-5 mb-xl-8'
            selectedYear={selectedYear}
            selectedMonth={selectedMonth}
            selectedDay={selectedDay}
          />
        </div>

        <div className='col-xxl-4'>
          <LastInvoicesActivitiesOnThisDay
            className='card-xxl-stretch mb-5 mb-xl-8'
            selectedYear={selectedYear}
            selectedMonth={selectedMonth}
            selectedDay={selectedDay}
          />
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row gy-5 g-xl-8'>
        <div className='col-xxl-6'>
          <GraphAmountQuotesByMonthInCurrentYear
            className='card-xxl-stretch mb-5 mb-xl-8'
            chartColor='orange'
            chartHeight='200px'
            selectedYear={selectedYear}
          />
        </div>

        <div className='col-xxl-6'>
          <GraphAmountOrdersByMonthInCurrentYear
            className='card-xxl-stretch mb-5 mb-xl-8'
            chartColor='success'
            chartHeight='200px'
            selectedYear={selectedYear}
          />
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row gy-5 g-xl-8'>
        <div className='col-xxl-6'>
          <GraphTurnoverByMonthInCurrentYear
            className='card-xxl-stretch mb-5 mb-xl-8'
            chartColor='info'
            chartHeight='200px'
            selectedYear={selectedYear}
          />
        </div>

        <div className='col-xxl-6'>
          <GraphRecoveriesByMonthInCurrentYear
            className='card-xxl-stretch mb-5 mb-xl-8'
            chartColor='warning'
            chartHeight='200px'
            selectedYear={selectedYear}
          />
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row gy-5 g-xl-8'>
        <div className='col-xxl-12'>
          <SalesStatisticsOnThisMonth
            className='card-xxl-stretch mb-5 mb-xl-8'
            selectedYear={selectedYear}
            selectedMonth={selectedMonth}
          />
        </div>
      </div>
      {/* end::Row */}
    </>
  )
}

export {DashboardView}
