/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import Switch from 'react-bootstrap-v5/lib/cjs/Switch'
import parse from 'html-react-parser'
import {Modal} from 'bootstrap'
import {randomString} from 'react-inlinesvg/lib/helpers'
import {priorities_type} from '../../../../Types'
import {responseApi, currentTime} from '../../../../Utilities'
import {useSafeState} from '../../../../../services/Hooks/useSafeState'
import {notify} from '../../../../../services/Toastify/Toastify'
import {ToDoFilters} from './ToDoFilters'
import PlanningModel from '../../../../../models/se/PlanningModel'
import {ToDoModal} from './ToDoModal'

type Props = {
  className: string
}

const ToDoLists: React.FC<Props> = ({className}) => {
  const [toDoList, setToDoList] = useSafeState(null)
  const [queryParams, setQueryParams] = useSafeState({is_in_todo: 1})
  const [tableReload, setTableReload] = useState(currentTime)
  const [planningId, setPlanningId] = useState(null)

  const fetchData = async (params: object) => {
    try {
      let dataModel = new PlanningModel()
      const {data} = await dataModel.getAll(params)
      if (data.type === 'success') {
        setToDoList(Object.values(data.result.data))
      }
    } catch (e) {
      setToDoList(null)
    }
  }

  const filter = (e: any) => {
    const {name, value} = e.target
    const operator = e.target.attributes.getNamedItem('data-operator').value
    const params = {...queryParams}
    params[name] = {
      value: value,
      operator: operator,
    }
    if (value === '') delete params[name]

    setQueryParams(params)
  }

  const modalActions = {
    open: (id: any) => {
      setPlanningId((prevState) => id)
      // @ts-ignore
      let myModal = new Modal(document.getElementById('todo_comment_modal'), {})
      myModal.show()
    },
    close: () => {
      setPlanningId(null)
    },
  }

  const priorityBadge = (priority: any) => {
    switch (priority) {
      case priorities_type[0].value:
        return (
          <>
            <span className='badge badge-danger'>{priorities_type[0].label}</span>
          </>
        )
      case priorities_type[1].value:
        return (
          <>
            <span className='badge badge-success'>{priorities_type[1].label}</span>
          </>
        )
      case priorities_type[2].value:
        return (
          <>
            <span className='badge badge-warning'>{priorities_type[2].label}</span>
          </>
        )
    }
  }

  const saveIsDoPlanning = async (id: any, checked: boolean) => {
    let response = responseApi
    const changeStatus = async () => {
      const planning = new PlanningModel()
      const status = checked ? 1 : 0
      const {data} = await planning.saveIsDoPlanning(id, status)
      response = data
      if (response.type !== null && response.message !== null) {
        notify(response.type, response.message)
        if (response.type === 'success') {
          setTableReload(currentTime)
        }
      }
    }
    await changeStatus()
  }

  useEffect(() => {
    ;(async () => await fetchData(queryParams))()
  }, [queryParams, tableReload])

  return (
    <>
      <div className={`card ${className}`}>
        {/* begin::Header */}
        <div className='card-header border-0'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-2 mb-1'>Liste des tâches à faire</span>
          </h3>
          <div className='card-toolbar'>
            {/* begin::Menu */}
            <button
              type='button'
              className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
            >
              <KTSVG
                path='/media/icons/duotone/Layout/Layout-4-blocks-2.svg'
                className='svg-icon-2'
              />
            </button>

            <ToDoFilters filter={filter} />
            {/* end::Menu */}
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className='card-body pt-2'>
          {/* begin::Item */}
          <div className='row'>
            {toDoList?.length > 0 &&
              toDoList.map((toDoListItem: any, toDoListIndex: number) => {
                return (
                  <div className='col-md-6 mb-5' key={randomString(8)}>
                    <div className='d-flex box-shadow h-100 '>
                      <div className='position-relative ps-3 w-100'>
                        <div className='d-flex bd-highlight mb-3'>
                          <div className='bd-highlight'>
                            {priorityBadge(toDoListItem?.priority_planning)}
                          </div>

                          <div
                            className='bd-highlight ms-auto'
                            title='Cliquez pour ajouter un client'
                            data-bs-toggle='tooltip'
                            data-bs-placement='top'
                            data-bs-trigger='hover'
                          >
                            <a
                              href={void 0}
                              className='btn btn-sm btn-light-success'
                              onClick={() => modalActions.open(toDoListItem?.id)}
                            >
                              <KTSVG
                                path='/media/icons/duotone/Communication/Chat.svg'
                                className='svg-icon-3'
                              />
                              Ajouter un commentaire
                            </a>
                          </div>

                          <div className='bd-highlight ms-auto'>
                            <Switch
                              className={'form-switch-xs'}
                              defaultChecked={parseInt(toDoListItem?.is_do) === 1}
                              onChange={(event) => {
                                saveIsDoPlanning(toDoListItem?.id, event.target.checked)
                              }}
                            />
                          </div>
                        </div>

                        <p className='mb-2'>
                          <b>N°BC : </b>
                          <a href={void 0} className='fw-bolder me-1'>
                            {toDoListItem?.gc_commande_num_bon}
                          </a>
                        </p>

                        <p className='mb-2'>
                          <b>Client : </b>
                          <a href={void 0} className='text-700 ps-1'>
                            {toDoListItem?.gc_client_nom_client +
                              ' | ' +
                              toDoListItem?.gc_client_address_client}
                          </a>
                        </p>

                        <p className='mb-2'>
                          <b>Observation :</b> {toDoListItem?.operateur_planning}
                        </p>

                        <p className='mb-2'>
                          <b>Date Fin : </b> {toDoListItem?.date_fin_planning}
                        </p>

                        <div className='separator border-gray-400 mb-2'></div>

                        <p className='mb-2'>
                          <b>Commentaire : </b>
                          <br />
                          <span className='fw-bolder text-orange me-1'>
                            {parse(toDoListItem?.comment_todo_planning || '')}
                          </span>
                        </p>

                        <div className='border-dashed-bottom my-3'></div>
                      </div>
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
        {/* end::Body */}
      </div>

      <ToDoModal
        planningId={planningId}
        modalActions={modalActions}
        setTableReload={setTableReload}
      />
    </>
  )
}

export {ToDoLists}
