/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {ProgressBarView} from '../../../../Utilities'

type Props = {
  loading: boolean
  objective: any
}

const ObjectiveYearProgressBar: React.FC<Props> = ({loading, objective}) => {
  return (
    <>
      <ProgressBarView
        percent={objective.percent}
        loading={loading}
        firstClassName='bg-white rounded h-30px w-100 mx-0 mb-3'
        secondClassName='bg-success rounded h-30px'
        textClassName='fw-bold text-white text-center fs-2'
        firstClassNameDefault='bg-white rounded h-30px w-100 mx-0 mb-3'
        secondClassNameDefault='bg-secondary rounded h-30px'
        textClassNameDefault='fw-bold text-white text-center fs-2'
      />
    </>
  )
}

export {ObjectiveYearProgressBar}
