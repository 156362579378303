/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSSVariableValue} from '../../../../../_metronic/assets/ts/_utils'
import {Currency, LoadingSpinner} from '../../../../Utilities'
import DashBoardModel from '../../../../../models/gc/DashBoardModel'
import {currentCurrencyName} from '../../../../Types'

type Props = {
  className: string
  chartColor: string
  chartHeight: string
  selectedYear: number
}

const initialRecoveryTotal = {
  recoveryTotal: 0,
}

const initialGraphData = {
  recoveryData: [],
}

const GraphRecoveriesByMonthInCurrentYear: React.FC<Props> = (props) => {
  const {className, chartColor, chartHeight, selectedYear} = props
  const [totalLoading, setTotalLoading] = useState(true)
  const [loading, setLoading] = useState(true)
  const [total, setRecoveryTotal] = useState(initialRecoveryTotal)
  const [graphData, setGraphData] = useState(initialGraphData)
  const chartRef = useRef<HTMLDivElement | null>(null)

  const getRecoveryTotal = async (year: number) => {
    setTotalLoading(true)
    let dataModel = new DashBoardModel()
    const {data} = await dataModel.getSectionTotalRecoveries(year)
    if (data.type === 'success') {
      setRecoveryTotal((prevState) => {
        return {...initialRecoveryTotal, recoveryTotal: data.result.recoveryTotal}
      })
    } else {
      setRecoveryTotal((prevState) => initialRecoveryTotal)
    }
    setTotalLoading(false)
  }

  const fetchGraphData = async (year: number) => {
    setLoading(true)
    let result = initialGraphData
    let dataModel = new DashBoardModel()
    await dataModel.getSectionGraphRecoveries(year).then((response: any) => {
      const {data} = response
      if (data.type === 'success') {
        result = {...initialGraphData, recoveryData: data.result.recoveryData}
      } else {
        result = initialGraphData
      }
    })
    setGraphData(result)
    setLoading(false)
  }

  useEffect(() => {
    getRecoveryTotal(selectedYear).then((response) => {})
    fetchGraphData(selectedYear)
  }, [selectedYear])

  useEffect(() => {
    if (!chartRef.current || loading) {
      return
    }

    const renderChart = async () => {
      const options = await chartOptions(chartColor, chartHeight, graphData)
      const chart = new ApexCharts(chartRef.current, options)

      if (chart) {
        chart.render()
      }

      return () => {
        if (chart) {
          chart.destroy()
        }
      }
    }

    renderChart()
  }, [chartRef, graphData, chartColor, chartHeight, loading])

  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-body d-flex flex-column p-0'>
        {/* begin::Memos */}
        <div className='flex-grow-1 card-p pb-0'>
          <div className='d-flex flex-stack flex-wrap'>
            <div className='me-2'>
              <span className='fw-bolder text-gray-800 d-block fs-3'>
                {`Recouvrements (TTC) de l'année ${selectedYear}`}
              </span>
            </div>

            <div className={`fw-bolder fs-3 text-${chartColor}`}>
              {totalLoading ? <LoadingSpinner /> : <Currency amount={total?.recoveryTotal} />}
            </div>
          </div>
        </div>
        {/* end::Memos */}

        {/* begin::Chart */}
        {loading ? (
          <LoadingSpinner parentClass='pt-1 pb-10' iconClass='fs-1' />
        ) : (
          <div ref={chartRef} className='mixed-widget-7-chart card-rounded-bottom'></div>
        )}
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}

const chartOptions = async (
  chartColor: string,
  chartHeight: string,
  graphData: typeof initialGraphData
): Promise<ApexOptions> => {
  const labelColor = getCSSVariableValue('--bs-gray-800')
  const strokeColor = getCSSVariableValue('--bs-gray-300')
  const baseColor = getCSSVariableValue('--bs-' + chartColor)
  const lightColor = getCSSVariableValue('--bs-light-' + chartColor)

  return {
    series: [
      {
        name: 'Recouvrements',
        data: graphData.recoveryData,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: chartHeight,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      categories: [
        'Jan',
        'Fév',
        'Mar',
        'Avr',
        'Mai',
        'Juin',
        'Juil',
        'Aoû',
        'Sep',
        'Oct',
        'Nov',
        'Déc',
      ],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        show: false,
        position: 'front',
        stroke: {
          color: strokeColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      min: 0,
      // max: 100,
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return val + ' ' + currentCurrencyName
        },
      },
    },
    colors: [lightColor],
    markers: {
      colors: [lightColor],
      strokeColors: [baseColor],
      strokeWidth: 3,
    },
  }
}

export {GraphRecoveriesByMonthInCurrentYear}
