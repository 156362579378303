/* eslint-disable react/jsx-no-target-blank */
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
//import { CheckAccess } from '../../../../app/Utilities';

export function AsideMenuMainSE() {
  return (
    <>
      <AsideMenuItemWithSub
        to='/execution/dashboard'
        title='Tableau de bord'
        icon='/media/icons/duotone/Home/Home.svg'
        fontIcon='bi-home'
        method={'dashboard-access'}
      >
        <AsideMenuItem
          to='/execution/dashboard/todo-lists'
          title='Liste des tâches'
          hasBullet={true}
          method={'dashboard-access'}
        />
        <AsideMenuItem
          to='/execution/dashboard/prestations'
          title='Suivi des prestations'
          hasBullet={true}
          method={'dashboard-access'}
        />
        <AsideMenuItem
          to='/execution/dashboard/plannings'
          title='Suivi des planifications'
          hasBullet={true}
          method={'dashboard-access'}
        />
      </AsideMenuItemWithSub>

      <AsideMenuItem
        to='/execution/statistics'
        icon='/media/icons/duotone/Shopping/Chart-bar1.svg'
        title='Statistiques'
        fontIcon='bi-home'
        method={'dashboard-access'}
      />

      <AsideMenuItem
        to='/execution/clients'
        icon='/media/icons/duotone/Communication/Adress-book2.svg'
        title='Liste des clients'
        fontIcon='bi-home'
        method={'dashboard-access'}
      />

      <AsideMenuItemWithSub
        to='/execution/prestations'
        title='Prestations offertes'
        icon='/media/icons/duotone/Shopping/Box2.svg'
        fontIcon='bi-home'
        method={'dashboard-access'}
      >
        <AsideMenuItem
          to='/execution/prestations/lists'
          title='Liste des prestations'
          hasBullet={true}
          method={'dashboard-access'}
        />
        <AsideMenuItem
          to='/execution/prestations/add'
          title='Définir une prestation'
          hasBullet={true}
          method={'dashboard-access'}
        />
      </AsideMenuItemWithSub>

      <AsideMenuItem
        to='/execution/orders'
        icon='/media/icons/duotone/Shopping/Cart1.svg'
        title='Bon de commandes'
        fontIcon='bi-home'
        method={'dashboard-access'}
      />

      <AsideMenuItem
        to='/execution/receptions'
        icon='/media/icons/duotone/Files/Group-folders.svg'
        title='Registre réception'
        fontIcon='bi-attachment'
        method={'dashboard-access'}
      />

      {/* <AsideMenuItem
        to='/execution/planning'
        icon='/media/icons/duotone/Communication/Clipboard-list.svg'
        title='Planning'
        fontIcon='bi-clipboard'
        method={"dashboard-access"}
      /> */}

      <AsideMenuItem
        to='/execution/interventions'
        icon='/media/icons/duotone/Map/Marker2.svg'
        title='Intervention sur site'
        fontIcon='bi-building'
        method={'dashboard-access'}
      />

      <AsideMenuItemWithSub
        to='/execution/certificats'
        title='Registre certificat'
        icon='/media/icons/duotone/Files/Selected-file.svg'
        fontIcon='bi-attachment'
        method={'dashboard-access'}
      >
        <AsideMenuItem
          to='/execution/certificats/lists'
          title='Liste des certificats'
          hasBullet={true}
          method={'dashboard-access'}
        />
        <AsideMenuItem
          to='/execution/certificats/received-orders' // '/execution/certificats/list-receptions'
          title='Générer un certificat'
          hasBullet={true}
          method={'dashboard-access'}
        />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/execution/constats'
        title='Registre constat'
        icon='/media/icons/duotone/General/Bookmark.svg'
        fontIcon='bi-attachment'
        method={'dashboard-access'}
      >
        <AsideMenuItem
          to='/execution/constats/lists'
          title='Liste des constats'
          hasBullet={true}
          method={'dashboard-access'}
        />
        <AsideMenuItem
          to='/execution/constats/received-orders' // '/execution/constats/list-receptions'
          title='Générer un constat'
          hasBullet={true}
          method={'dashboard-access'}
        />
      </AsideMenuItemWithSub>

      <AsideMenuItem
        to='/execution/livraisons'
        icon='/media/icons/duotone/Shopping/Cart5.svg'
        title='Gestion des livraisons'
        fontIcon='bi-attachment'
        method={'dashboard-access'}
      />

      <AsideMenuItem
        to='/execution/request-contract-reviews'
        icon='/media/icons/duotone/Shopping/Wallet3.svg'
        title='Revue de demande/contrat'
        fontIcon='bi-home'
        method={'dashboard-access'}
      />

      <AsideMenuItemWithSub
        to='/execution/validations'
        title='Suivi des validations'
        icon='/media/icons/duotone/Files/File-done.svg'
        fontIcon='bi-attachment'
        method={'dashboard-access'}
      >
        <AsideMenuItem
          to='/execution/validations/in-progress'
          title='Validations en cours'
          hasBullet={true}
          method={'dashboard-access'}
        />
        <AsideMenuItem
          to='/execution/validations/finished'
          title='Validations terminées'
          hasBullet={true}
          method={'dashboard-access'}
        />
      </AsideMenuItemWithSub>

      {/*  */}
      <div className='menu-item'>
        <div className='menu-content'>{/* <div className='separator mx-1 my-4'></div> */}</div>
      </div>
    </>
  )
}
