import React, {useEffect, useState} from 'react'
import {months_type} from '../../Types'
import {getDaysInMonth} from '../../Utilities'
// import {useLoading} from '../../../services/Hooks/LoadingContext'

interface FilterProps {
  startYear: number
  selectedYear: number
  onYearChange: (year: number) => void
  selectedMonth?: number
  onMonthChange?: (month: number) => void
  selectedDay?: number
  onDayChange?: (day: number) => void
}

export const DashboardFilter: React.FC<FilterProps> = ({
  startYear,
  selectedYear,
  onYearChange,
  selectedMonth,
  onMonthChange,
  selectedDay,
  onDayChange,
}) => {
  const currentYear = new Date().getFullYear()
  const yearArrayLength = currentYear - startYear + 1
  const years = Array.from(new Array(yearArrayLength), (_, index) => currentYear - index)
  const months = months_type
  // const days = Array.from(new Array(31), (_, index) => index + 1)
  const [days, setDays] = useState<number[]>([])

  // const {loadingStates} = useLoading()
  // const isLoading = Object.values(loadingStates).some((state) => state)

  useEffect(() => {
    const daysInMonth = getDaysInMonth(selectedYear, selectedMonth || new Date().getMonth())
    const daysArray = Array.from({length: daysInMonth}, (_, index) => index + 1)
    setDays(daysArray)
  }, [selectedYear, selectedMonth])

  return (
    <>
      <div className='card mb-5'>
        <div className='card-body p-4'>
          <div className='row justify-content-center'>
            <div className='col-lg-4 my-1'>
              <div className='input-group'>
                <select
                  value={selectedYear}
                  onChange={(e) => onYearChange(parseInt(e.target.value, 10))}
                  className='form-select form-select-solid form-select-lg fw-bold'
                >
                  {years.map((year) => (
                    <option key={'year_' + year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {onMonthChange && (
              <div className='col-lg-4 my-1'>
                <div className='input-group'>
                  <select
                    value={selectedMonth}
                    onChange={(e) => onMonthChange(parseInt(e.target.value, 10))}
                    className='form-select form-select-solid form-select-lg fw-bold'
                  >
                    {months.map((month, index) => (
                      <option key={'month_' + index} value={month.value}>
                        {month.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}

            {onDayChange && (
              <div className='col-lg-4 my-1'>
                <div className='input-group'>
                  <select
                    value={selectedDay}
                    onChange={(e) => onDayChange(parseInt(e.target.value, 10))}
                    className='form-select form-select-solid form-select-lg fw-bold'
                  >
                    {days.map((day) => (
                      <option key={'day_' + day} value={day}>
                        {day}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
