/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import {LoadingSpinner} from '../../../../Utilities'

type Props = {
  loading: boolean
  count: any
}

const CountActifCustomersHasOrderInPreviousAndCurrentYear: React.FC<Props> = ({loading, count}) => {
  return (
    <>
      {/* begin::Col */}
      <div className='col bg-light-warning px-6 py-8 rounded-2 mb-7 ms-2 me-2'>
        <div className='d-flex align-items-center flex-column text-center'>
          <KTSVG
            path='/media/icons/duotone/General/Shield-check.svg'
            className='svg-icon-2x svg-icon-warning d-block my-2 me-2'
          />
          <div className='fw-bold fw-bolder fs-5 text-warning mt-3'>Nombre de clients actif</div>

          {loading ? (
            <LoadingSpinner parentClass='pt-5' iconClass='fs-4' />
          ) : (
            <div className='fw-bold fw-bolder fs-1 text-warning mt-3'>{count}</div>
          )}
        </div>
      </div>
      {/* end::Col */}
    </>
  )
}

export {CountActifCustomersHasOrderInPreviousAndCurrentYear}
