import React from 'react'
import store, {RootState} from '../setup/redux/Store'
import {shallowEqual, useSelector} from 'react-redux'
import {actions as appActions} from '../setup/redux/AppRedux'
import AccountModel from '../models/AccountModel'
import {FormattedNumber, IntlProvider} from 'react-intl'
import {months_type} from './Types'
import './GlobalLoader.css'

export const user = store.getState().authReducer.user
export const accessToken = store.getState().authReducer.accessToken
export const currentApp = store.getState().authReducer.currentApp

// @ts-ignore
export let responseApi = {
  type: null,
  message: null,
  result: null,
}

export interface InterfaceArrayObject {
  // @ts-ignore
  [key: any]: any
}

interface propsDownloadOrDeleteFileButton {
  fileUrl: string
  onResetInputFile: () => void
}

export const uniqueKey = (length = 8) => {
  let result = ''
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

// @ts-ignore
export const DownloadOrDeleteFileButton = ({fileUrl, onResetInputFile}) => {
  if (fileUrl)
    return (
      <>
        <div style={{fontSize: '11px'}}>
          <a className='link-primary me-1' target='_blank' href={fileUrl} download>
            Télécharger
          </a>
          |
          <a
            href='#'
            className='link-danger ms-1'
            onClick={() => {
              onResetInputFile()
            }}
          >
            Supprimer
          </a>
        </div>
      </>
    )
  return <></>
}

export const buildFormData = (formData: any, data: any, parentKey?: any) => {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach((key) => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key)
    })
  } else {
    const value = data == null ? '' : data

    formData.append(parentKey, value)
  }
}

export const saveFile = async (blob: any, fileName: string) => {
  const a = document.createElement('a')
  a.download = fileName
  a.href = URL.createObjectURL(blob)
  a.addEventListener('click', (e) => {
    setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000)
  })
  a.click()
}

export const CheckAccess = (method: string, submodule?: string) => {
  const appReducer = useSelector((state: RootState) => state.appReducer, shallowEqual)
  const userAccess = appReducer.currentUserAccess
  const setting = appReducer.setting
  if (!userAccess.length) return false
  if (!submodule) {
    return userAccess.indexOf(method) > -1
  } else {
    return userAccess.indexOf(method) > -1 && setting[submodule] > 0
  }
}

export const isArray = (obj: any) => {
  return !!obj && obj.constructor === Array
}

export const removeItemFromArray = (array: [], item: any) => {
  // @ts-ignore
  var index = array.indexOf(item)
  if (index !== -1) {
    array.splice(index, 1)
  }
  return array
}

export const removeItemsFromArray = (array: [], items: []) => {
  items.length > 0 &&
    items.forEach((item) => {
      let index = array.indexOf(item)
      if (index !== -1) {
        array.splice(index, 1)
      }
    })
  return array
}

export const removeDuplicateItemsFromArray = (array: []) => {
  return Array.from(new Set(array))
}

export const findItemByKeyAndValueInArray = (array: [], key: any, value: any) => {
  return array.find((element) => {
    return element[key] === value
  })
}

export const itemsFromItemsByKey = (items: [], key: string) => {
  let itemsByKey: any = []
  items.length > 0 &&
    items.forEach((item) => {
      itemsByKey.push(item[key])
    })
  return itemsByKey
}

export const objectHasValue = (obj: object, value: string) => {
  // @ts-ignore
  const find = (val: any) => typeof val === 'string' && obj[val] === value
  return Object.keys(obj).some(find)
}

export const itemsFromItemsByValue = (items: [], value: string) => {
  /* const containsKeyword = (val : any)  => typeof val === "string" && val.indexOf(value) !== -1;
    return   items.filter((entry : any) => Object.keys(entry).map(key => entry[key]).some(containsKeyword));*/
  return items.filter((entry: any) => objectHasValue(entry, value))
}

export const findItemByKeyAndValueInArrayObject = (
  array: InterfaceArrayObject,
  key: any,
  value: any
) => {
  // @ts-ignore
  return array.find((element: object) => {
    // @ts-ignore
    //console.log(element[key])
    // @ts-ignore
    return element[key] === value
  })
}

export const getLabel = (type: any, value: any) => {
  let find = findItemByKeyAndValueInArrayObject(type, 'value', value)
  return find?.label || ''
}

export const currentTime = () => {
  return new Date().getTime()
}

export const mysqlDate = () => {
  return new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000)
    .toJSON()
    .slice(0, 19)
    .replace('T', ' ')
}

export const formatNumberWithoutZero = (number: number): string => {
  return number.toString().padStart(2, '0')
}

export const formatDate = (year: number, month: number, day: number): string => {
  const formattedMonth = month < 10 ? `0${month}` : month.toString()
  const formattedDay = day < 10 ? `0${day}` : day.toString()
  return `${year}-${formattedMonth}-${formattedDay}`
}

export const getDaysInMonth = (year: number, month: number): number => {
  return new Date(year, month, 0).getDate()
}

export const getMonthName = (monthValue: number, abbreviated = false): string => {
  const month = months_type.find((m) => m.value === monthValue)
  if (!month) {
    return ''
  }
  return abbreviated ? month.abbr : month.label
}

export const CurrentUser = async (dispatch: any) => {
  let dataModel = new AccountModel()
  const {data} = await dataModel.getMyAccount()
  if (data.type === 'success') {
    await dispatch(appActions.saveCurrentUser(data.result))
    return data.result
  }
}

export const MyAccount = async () => {
  let dataModel = new AccountModel()
  const {data} = await dataModel.getMyAccount()
  if (data.type === 'success') {
    return data.result
  }
  return null
}

export const Currency = (props: any) => {
  const currentUser = useSelector((state: RootState) => state.authReducer.user, shallowEqual)
  const appCurrency = useSelector((state: RootState) => state.appReducer.currency, shallowEqual)
  let amount = parseFloat(props.amount)
  amount = isNaN(amount) ? 0 : amount
  const currency = props?.currencyCode ? props?.currencyCode : appCurrency?.code
  const minimum = 2
  const maximum = 2
  return (
    <IntlProvider locale={currentUser?.lang?.locale || navigator.language}>
      <FormattedNumber
        value={amount}
        style='currency'
        currency={currency}
        minimumFractionDigits={minimum}
        maximumFractionDigits={maximum}
      />
    </IntlProvider>
  )
}

export const Number = (props: any) => {
  const currentUser = useSelector((state: RootState) => state.authReducer.user, shallowEqual)
  let value = parseFloat(props.value)
  value = isNaN(value) ? 0 : value
  const minimum = 2
  const maximum = 2
  //console.log(navigator.language)
  return (
    <IntlProvider locale={currentUser?.lang?.locale || navigator.language}>
      <FormattedNumber
        value={value}
        minimumFractionDigits={minimum}
        maximumFractionDigits={maximum}
      />
    </IntlProvider>
  )
}

/**
 * merge array object key values
 * @param data
 * @param keyToMerge
 * @returns
 */
export const mergeArrayObjectKeyValues = (data: any[], keyToMerge: string): any[] => {
  return data.reduce((acc: any[], current: any) => {
    return acc.concat(current[keyToMerge])
  }, [])
}

/**
 * filtered array object return keys
 * @param data
 * @param keysToKeep
 * @returns
 */
export const filteredArrayObjectReturnKeys = (data: any[], keysToKeep: string[]): any[] => {
  return data.map((line) => {
    const filteredLine: any = {}
    keysToKeep.forEach((key) => {
      if (key in line) {
        filteredLine[key] = line[key]
      }
    })
    return filteredLine
  })
}

/**
 * get validator info by key
 * @param perons
 * @param key
 * @param enableClass
 * @param disableClass
 * @returns
 */
export const getValidatorInfoByKey = (
  perons: [],
  key: string = 'nom',
  enableClass: string = 'success',
  disableClass: string = 'secondary'
) => {
  if (perons && perons.length > 0) {
    return perons.map((personItem: any, personIndex) => {
      const classStyle =
        personItem?.pivot && personItem?.pivot.checked === 1 ? enableClass : disableClass
      return (
        <span key={'tabSpan' + personIndex} className={`badge badge-${classStyle} m-1`}>
          {personItem[key]}
        </span>
      )
    })
  }
  return '---'
}

// text formatter interface
interface TextFormatterProps {
  text: string
  format: 'uppercase' | 'lowercase' | 'capitalize' | 'uncapitalize'
  renderAsSpan?: boolean
  spanClassName?: string
}

/**
 * format text
 * @param text
 * @param format
 * @returns
 */
export const formatText = (text: string, format: string): string => {
  switch (format) {
    case 'uppercase':
      return text.toUpperCase()
    case 'lowercase':
      return text.toLowerCase()
    case 'capitalize':
      return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
    case 'uncapitalize':
      return text.charAt(0).toLowerCase() + text.slice(1)
    default:
      return text
  }
}
/**
 * text formatter
 * @param props
 * @returns
 */
export const TextFormatter: React.FC<TextFormatterProps> = (props) => {
  const {text, format, renderAsSpan = false, spanClassName = ''} = props
  const formattedText = formatText(text, format)
  if (renderAsSpan) return <span className={spanClassName}>{formattedText}</span>
  return <>{formattedText}</>
}

// Download Button Component interface
interface DownloadButtonProps {
  url: string
  filename?: string
  className?: string
  title?: string
  style?: React.CSSProperties
  icon?: React.ReactNode
  children?: React.ReactNode
  printable?: boolean
}
/**
 * Download Button Component
 * @param props
 * @returns
 */
export const DownloadButton: React.FC<DownloadButtonProps> = (props) => {
  const {url, filename, className, title, style, icon, children, printable = false} = props
  return (
    <>
      {url && (
        <a
          href={url}
          download={filename}
          className={className + ' print-btn'}
          style={style}
          title={title}
          target='_blank'
          rel='noopener noreferrer'
        >
          {icon && <span>{icon}</span>}
          {children}
        </a>
      )}
      {printable && url && (
        <div style={{display: 'none'}} className='print-link'>
          <a href={url} download={filename} target='_blank' rel='noopener noreferrer'>
            {children || 'Télécharger'}
          </a>
        </div>
      )}
      <style>
        {`
          @media print {
            .print-link {
              display: block !important;
            }
            .print-btn {
              display: none !important;
            }
          }
        `}
      </style>
    </>
  )
}

// Loading view
export const LoadingView = () => {
  return (
    <div className='d-flex justify-content-center'>
      <div className='spinner-border text-primary' role='status'>
        <span className='visually-hidden'>Loading...</span>
      </div>
    </div>
  )
}

// Loading spinner type
type LoadingSpinnerProps = {
  parentClass?: string
  parentStyle?: React.CSSProperties
  iconClass?: string
  iconStyle?: React.CSSProperties
}
/**
 * Loading spinner
 * @param props
 * @returns
 */
export const LoadingSpinner: React.FC<LoadingSpinnerProps> = (props) => {
  const {parentClass, parentStyle, iconClass, iconStyle} = props
  return (
    <div
      className={`d-flex justify-content-center align-items-center ${parentClass}`}
      style={parentStyle}
    >
      <div className='loading-spinner-container'>
        <div className='loading-spinner'>
          <i className={`fas fa-spinner fa-spin ${iconClass}`} style={iconStyle}></i>
        </div>
      </div>
    </div>
  )
}

// Progress bar view type
type ProgressBarViewProps = {
  percent: number
  loading?: boolean
  loadingView?: React.ReactNode
  firstClassName?: string
  secondClassName?: string
  textClassName?: string
  firstClassNameDefault?: string
  secondClassNameDefault?: string
  textClassNameDefault?: string
}

/**
 * Progress bar view
 * @param props
 * @returns
 */
export const ProgressBarView: React.FC<ProgressBarViewProps> = (props) => {
  const {
    percent,
    loading = false,
    loadingView = <LoadingSpinner />,
    firstClassName = 'bg-white rounded h-20px w-100 mx-0 mb-3',
    secondClassName = 'bg-success rounded h-20px',
    textClassName = 'fw-bold text-white text-center',
    firstClassNameDefault = 'bg-white rounded h-20px w-100 mx-0 mb-3',
    secondClassNameDefault = 'bg-secondary rounded h-20px',
    textClassNameDefault = 'fw-bold text-white text-center',
  } = props

  return (
    <>
      <div className={percent ? firstClassName : firstClassNameDefault}>
        {loading ? (
          loadingView
        ) : (
          <div
            className={percent ? secondClassName : secondClassNameDefault}
            role='progressbar'
            style={{width: (percent ? percent : 100) + '%'}}
          >
            <div className={percent ? textClassName : textClassNameDefault}>
              {(percent ? percent : 0) + ' %'}
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export const GlobalLoader: React.FC = () => {
  return (
    <div className='global-loader-overlay'>
      <div className='spinner'></div>
    </div>
  )
}
