/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {useDispatch} from 'react-redux'
import {Modal} from 'bootstrap'
import {actions as authActions} from './AuthRedux'
import {actions as appActions} from '../../setup/redux/AppRedux'
import AuthModel from '../../models/AuthModel'
import GlobalModel from '../../models/GlobalModel'
import {GeneralConditionsModal} from '../pages/general_condition/components/GeneralConditionsModal'

const initialValues = {
  chooseApp: '',
  login: '',
  password: '',
  chooseDepartment: '',
}

// interface InterfaceDepartements {
//   // @ts-ignore
//   [key: any]: any
// }

const loginSchema = Yup.object().shape({
  chooseApp: Yup.string().required("Choix de l'application obligatoire !"),
  login: Yup.string()
    .min(1, '1 Caractère au minimum !')
    .max(15, '15 Caractère au maximum !')
    .required("Nom d'utilisateur requis !"),
  password: Yup.string()
    .min(1, '1 Caractère au minimum !')
    .max(25, '25 Caractère au maximum !')
    .required('Mot de passe requis !'),
  chooseDepartment: Yup.string().required('Choix de département obligatoire !'),
})

export function Login() {
  const [departments, setDepartments] = useState([])
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const findDepartment = async (departmentId: any) => {
    let dataModel = new GlobalModel()
    const {data} = await dataModel.findDep(departmentId)
    if (data.type === 'success') {
      return data.result.design_dep
    }
    return null
  }

  const [generalConditionId, setGeneralConditionId] = useState(null)
  const modalActions = {
    open: (id: any) => {
      setGeneralConditionId((prevState) => id)
      // @ts-ignore
      let myModal = new Modal(document.getElementById('general_condition_modal'), {})
      myModal.show()
    },
    close: () => {
      setGeneralConditionId((prevState) => null)
    },
  }

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(() => {
        const auth = new AuthModel()
        // console.log(values)
        auth
          .login({
            chooseApp: values.chooseApp,
            login: values.login,
            password: values.password,
            chooseDepartment: values.chooseDepartment,
          })
          .then((response) => {
            //console.log(response)
            if (response.data.type === 'success') {
              //   ;(async () => {
              //     await dispatch(authActions.authSuccess(response.data.result))
              //     await dispatch(appActions.saveCurrency(response.data.result.settings.currency))
              //     await dispatch(appActions.saveCurrentApp(values.chooseApp))
              //     const cdiDesign = await findDepartment(values.chooseDepartment)
              //     await dispatch(
              //       appActions.saveCurrentDepartment({
              //         cdi: values.chooseDepartment,
              //         cdiDesign: cdiDesign,
              //       })
              //     )
              //   })()

              findDepartment(values.chooseDepartment).then((cdiDesign) => {
                if (cdiDesign) {
                  dispatch(authActions.authSuccess(response.data.result))
                  dispatch(appActions.saveCurrency(response.data.result.settings.currency))
                  dispatch(appActions.saveCurrentApp(values.chooseApp))
                  dispatch(
                    appActions.saveCurrentDepartment({
                      cdi: values.chooseDepartment,
                      cdiDesign: cdiDesign,
                    })
                  )
                }
              })
              //getCurrentUserAccess()
            } else {
              setStatus("Nom d'utilisateur ou mot de passe ou département incorrect!")
            }
            setLoading(false)
            setSubmitting(false)
          })
          .catch(() => {
            setLoading(false)
            setSubmitting(false)
            setStatus('The login detail is incorrect')
          })
      }, 1000)
    },
  })

  const getChooseAppValue = (event: any) => {
    formik.setFieldValue('chooseApp', event.target.value)
  }

  const getAllDepartment = async () => {
    let dataModel = new GlobalModel()
    const {data} = await dataModel.getDepartments()
    if (data.type === 'success') {
      return data.result.data
      //setDepartments( data.result.data)
    } else {
      //setDepartments( [])
      return []
    }
  }

  /**/
  useEffect(() => {
    let isCancelled = false
    getAllDepartment().then((response: any) => {
      if (!isCancelled) setDepartments((prevState) => response)
    })
    return () => {
      isCancelled = true
    }
  }, [])

  return (
    <>
      <form
        id='kt_login_signin_form'
        onSubmit={formik.handleSubmit}
        className='form w-100'
        noValidate
      >
        <div className='text-center mb-5 bg-black p-8 rounded'>
          <div className='fw-bolder text-uppercase'>
            <h1 className='text-white mb-0'>Authentification</h1>
          </div>
        </div>

        {formik.status ? (
          <>
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          </>
        ) : (
          <></>
        )}

        {/* begin::Form group */}
        <div className='fv-row mb-5'>
          <div className='align-items-center'>
            <label className='col-12 form-check form-check-inline form-check-solid mb-2'>
              <span className='fw-bold ps-2 fs-6'>Gestion parc instruments</span>
              <input
                type='radio'
                value='GP'
                name='custom_choose_app'
                className='form-check-input'
                onChange={(event) => {
                  getChooseAppValue(event)
                }}
              />
            </label>

            <label className='col-12 form-check form-check-inline form-check-solid mb-2'>
              <span className='fw-bold ps-2 fs-6'>Gestion suivi des éxécutions</span>
              <input
                type='radio'
                value='SE'
                name='custom_choose_app'
                className='form-check-input'
                onChange={(event) => {
                  getChooseAppValue(event)
                }}
              />
            </label>

            <label className='col-12 form-check form-check-inline form-check-solid mb-2'>
              <span className='fw-bold ps-2 fs-6'>Gestion commerciale</span>
              <input
                type='radio'
                value='GC'
                name='custom_choose_app'
                className='form-check-input'
                onChange={(event) => {
                  getChooseAppValue(event)
                }}
              />
            </label>

            <label className='col-12 form-check form-check-inline form-check-solid mb-2'>
              <span className='fw-bold ps-2 fs-6'>Reporting</span>
              <input
                type='radio'
                value='RE'
                name='custom_choose_app'
                className='form-check-input'
                onChange={(event) => {
                  getChooseAppValue(event)
                }}
              />
            </label>
          </div>
          {formik.touched.chooseApp && formik.errors.chooseApp && (
            <div className='fv-plugins-message-container text-danger'>
              <span role='alert'>{formik.errors.chooseApp}</span>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-5'>
          <label className='form-label fs-6 fw-bolder text-dark'>
            <span className='required'>Nom d'utilisateur</span>
          </label>
          <input
            type='text'
            {...formik.getFieldProps('login')}
            name='login'
            placeholder='Login'
            autoComplete='on'
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.login && formik.errors.login},
              {'is-valid': formik.touched.login && !formik.errors.login}
            )}
          />
          {formik.touched.login && formik.errors.login && (
            <div className='fv-plugins-message-container text-danger'>
              <span role='alert'>{formik.errors.login}</span>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-5'>
          <div className='d-flex justify-content-between'>
            <div className='d-flex flex-stack mb-2'>
              <label className='form-label fw-bolder text-dark fs-6 mb-0'>
                <span className='required'>Mot de passe</span>
              </label>
              {/* <Link
                to='/auth/forgot-password'
                className='link-primary fs-6 fw-bolder'
                style={{marginLeft: '5px'}}
              >
                (J'ai oublié ?)
              </Link> */}
            </div>
          </div>
          <input
            type='password'
            {...formik.getFieldProps('password')}
            name='password'
            placeholder='******'
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.password && formik.errors.password},
              {'is-valid': formik.touched.password && !formik.errors.password}
            )}
          />
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container text-danger'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-5'>
          <label className='form-label fw-bolder text-dark fs-6 mb-0'>
            <span className='required'>Département</span>
          </label>
          <select
            {...formik.getFieldProps('chooseDepartment')}
            name='chooseDepartment'
            className='form-select form-select-solid form-select-lg fw-bold'
          >
            <option value=''>-- Sélectionnez le département --</option>
            {departments &&
              departments.map((row: any) => {
                return (
                  <option key={row.id + '_option'} value={row.id}>
                    {row.design_dep}
                  </option>
                )
              })}
          </select>
          {formik.touched.chooseDepartment && formik.errors.chooseDepartment && (
            <div className='fv-plugins-message-container text-danger'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.chooseDepartment}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mt-5 mb-5'>
          <div
            className='form-check form-switch-sm form-check-custom form-check-solid'
            style={{cursor: 'pointer'}}
          >
            <a
              href={void 0}
              title="Veuillez lire et accepter les conditions générales d'utilisation !"
              className='fw-bolder fs-7 text-gray-600'
              onClick={() => modalActions.open(null)}
            >
              En accédant à cette application je confirme mon accord aux conditions générales{' '}
              <span className='text-primary'>(Cliquez ici !)</span>
            </a>
          </div>
        </div>
        {/* end::Form group */}

        {/* begin::Action */}
        <div className='text-center mt-10'>
          <button
            type='submit'
            id='kt_sign_in_submit'
            disabled={formik.isSubmitting || !formik.isValid}
            className='btn btn-lg btn-orange w-100 mb-5'
          >
            {!loading && (
              <span className='indicator-label text-white text-uppercase'>Je me connecte</span>
            )}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Action */}
      </form>

      <GeneralConditionsModal
        generalConditionId={generalConditionId}
        modalActions={modalActions}
        className={'className'}
      />
    </>
  )
}
