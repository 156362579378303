/* eslint-disable react/jsx-no-target-blank */
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
//import { CheckAccess } from '../../../../app/Utilities';

export function AsideMenuMainGC() {
  return (
    <>
      <AsideMenuItem
        to='/business/dashboard'
        icon='/media/icons/duotone/Home/Home.svg'
        title='Tableau de bord'
        fontIcon='bi-home'
        method={'dashboard-access'}
      />

      <AsideMenuItemWithSub
        to='/business/stats'
        title='Statistiques'
        icon='/media/icons/duotone/Shopping/Chart-line1.svg'
        fontIcon='bi-attachment'
        method={'dashboard-access'}
      >
        <AsideMenuItem
          to='/business/stats/potential-turnover'
          title="Chiffre d'affaires potentiel"
          hasBullet={true}
          method={'dashboard-access'}
        />
        <AsideMenuItem
          to='/business/stats/commercial-turnover'
          title='Ventes'
          hasBullet={true}
          method={'dashboard-access'}
        />
        <AsideMenuItem
          to='/business/stats/clients'
          title='Clients'
          hasBullet={true}
          method={'dashboard-access'}
        />
      </AsideMenuItemWithSub>

      <AsideMenuItem
        to='/business/clients'
        icon='/media/icons/duotone/Communication/Adress-book2.svg'
        title='Gestion des clients'
        fontIcon='bi-home'
        method={'dashboard-access'}
      />

      <AsideMenuItemWithSub
        to='/business/quotes'
        title='Gestion des devis'
        icon='/media/icons/duotone/Shopping/Cart4.svg'
        fontIcon='bi-attachment'
        method={'dashboard-access'}
      >
        <AsideMenuItem
          to='/business/quotes/lists'
          title='Liste des devis'
          hasBullet={true}
          method={'dashboard-access'}
        />
        <AsideMenuItem
          to='/business/quotes/add'
          title='Enregistrer un devis'
          hasBullet={true}
          method={'dashboard-access'}
        />
        <AsideMenuItem
          to='/business/quotes/merge'
          title='Fusionner des devis'
          hasBullet={true}
          method={'dashboard-access'}
        />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/business/orders'
        title='Gestion des commandes'
        icon='/media/icons/duotone/Shopping/Cart1.svg'
        fontIcon='bi-attachment'
        method={'dashboard-access'}
      >
        <AsideMenuItem
          to='/business/orders/lists'
          title='Liste des commandes'
          hasBullet={true}
          method={'dashboard-access'}
        />
        <AsideMenuItem
          to='/business/orders/relances'
          title='Relances de commande'
          hasBullet={true}
          method={'dashboard-access'}
        />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/business/invoices'
        title='Gestion des factures'
        icon='/media/icons/duotone/Shopping/Credit-card.svg'
        fontIcon='bi-attachment'
        method={'dashboard-access'}
      >
        <AsideMenuItem
          to='/business/invoices/lists'
          title='Liste des factures'
          hasBullet={true}
          method={'dashboard-access'}
        />
        <AsideMenuItem
          to='/business/invoices/recoveries'
          title='Recouvrements'
          hasBullet={true}
          method={'dashboard-access'}
        />
        <AsideMenuItem
          to='/business/invoices/memos'
          title='Etat de ventes'
          hasBullet={true}
          method={'dashboard-access'}
        />
      </AsideMenuItemWithSub>

      <AsideMenuItem
        to='/business/request-contract-reviews'
        icon='/media/icons/duotone/Shopping/Wallet3.svg'
        title='Revue de demande/contrat'
        fontIcon='bi-home'
        method={'dashboard-access'}
      />

      <AsideMenuItem
        to='/business/settings'
        icon='/media/icons/duotone/General/Settings-2.svg'
        title='Paramétrage'
        fontIcon='bi-sttings'
        method={'setting-access'}
      />

      {/*  */}
      <div className='menu-item'>
        <div className='menu-content'>{/* <div className='separator mx-1 my-4'></div> */}</div>
      </div>
    </>
  )
}
