/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSSVariableValue} from '../../../../../_metronic/assets/ts/_utils'
import DashBoardModel from '../../../../../models/gc/DashBoardModel'
import {currentCurrencyName} from '../../../../Types'
import {LoadingSpinner} from '../../../../Utilities'

type Props = {
  className: string
  chartColor: string
  chartHeight: string
  selectedYear: number
}

const initialGraphData = {
  orderData: [],
}

const GraphAmountOrdersByMonthInCurrentYear: React.FC<Props> = (props) => {
  const {className, chartColor, chartHeight, selectedYear} = props
  const [loading, setLoading] = useState(true)
  const [graphData, setGraphData] = useState(initialGraphData)
  const chartRef = useRef<HTMLDivElement | null>(null)

  const fetchGraphData = async (year: number) => {
    setLoading(true)
    let result = initialGraphData
    let dataModel = new DashBoardModel()
    await dataModel.getSectionGraphOrders(year).then((response: any) => {
      const {data} = response
      if (data.type === 'success') {
        result = {...initialGraphData, orderData: data.result.orderData}
      } else {
        result = initialGraphData
      }
    })
    setGraphData(result)
    setLoading(false)
  }

  useEffect(() => {
    fetchGraphData(selectedYear)
  }, [selectedYear])

  useEffect(() => {
    if (!chartRef.current || loading) {
      return
    }

    const renderChart = async () => {
      const options = await chartOptions(chartColor, chartHeight, graphData)
      const chart = new ApexCharts(chartRef.current, options)

      if (chart) {
        chart.render()
      }

      return () => {
        if (chart) {
          chart.destroy()
        }
      }
    }

    renderChart()
  }, [chartRef, graphData, chartColor, chartHeight, loading])

  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-body p-0 d-flex justify-content-between flex-column overflow-hidden'>
        {/* begin::Hidden */}
        <div className='d-flex flex-stack flex-wrap flex-grow-1 px-9 pt-9 pb-3'>
          <div className='me-2'>
            <span className='fw-bolder text-gray-800 d-block fs-2'>
              {`Statistiques des commandes de l'année ${selectedYear} (en HT)`}
            </span>
          </div>

          <div className={`fw-bolder fs-3 text-${chartColor}`}>{}</div>
        </div>
        {/* end::Hidden */}

        {/* begin::Chart */}
        {loading ? (
          <LoadingSpinner parentClass='pt-1 pb-10' iconClass='fs-1' />
        ) : (
          <div ref={chartRef} className='mixed-widget-10-chart'></div>
        )}
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}

const chartOptions = async (
  chartColor: string,
  chartHeight: string,
  graphData: typeof initialGraphData
): Promise<ApexOptions> => {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const secondaryColor = getCSSVariableValue('--bs-gray-300')
  const baseColor = getCSSVariableValue('--bs-' + chartColor)

  return {
    series: [
      {
        name: 'Commandes',
        data: graphData.orderData,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: chartHeight,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '50%',
        borderRadius: 5,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: [
        'Jan',
        'Fév',
        'Mar',
        'Avr',
        'Mai',
        'Juin',
        'Juil',
        'Aoû',
        'Sep',
        'Oct',
        'Nov',
        'Déc',
      ],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    fill: {
      type: 'solid',
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return '' + val + ' ' + currentCurrencyName
        },
      },
    },
    colors: [baseColor, secondaryColor],
    grid: {
      padding: {
        top: 10,
      },
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}

export {GraphAmountOrdersByMonthInCurrentYear}
