/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {ToDoLists} from './components/todo_lists/ToDoLists'
import {Prestations} from './components/prestations/Prestations'
import {Plannings} from './components/plannings/Plannings'

// type Props = {
//   className: string
// }

const breadCrumbs: Array<PageLink> = [
  {
    title: 'Tableau de bord',
    path: '/execution/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const DashboardExecution: FC = () => {
  return (
    <>
      <Switch>
        <Route path='/execution/dashboard/todo-lists' exact={true}>
          <PageTitle breadcrumbs={breadCrumbs}>Liste des tâches</PageTitle>
          <ToDoLists className={''} />
        </Route>

        <Route path='/execution/dashboard/prestations' exact={true}>
          <PageTitle breadcrumbs={breadCrumbs}>Suivi des prestations</PageTitle>
          <Prestations />
        </Route>

        <Route path='/execution/dashboard/plannings' exact={true}>
          <PageTitle breadcrumbs={breadCrumbs}>Suivi des planifications</PageTitle>
          <Plannings />
        </Route>

        <Redirect from='/execution/dashboard' exact={true} to='/execution/dashboard/todo-lists' />
        <Redirect to='/error/404' />
      </Switch>
    </>
  )
}

export {DashboardExecution}
