import {useCallback, useEffect, useRef, useState} from 'react'

export function useSafeState(initialValue: any) {
  const isMounted = useRef(false)
  const [state, setState] = useState(initialValue)
  // @ts-ignore
  useEffect(() => {
    isMounted.current = true
    return () => (isMounted.current = false)
  }, [])

  function setStateSafe(value: any) {
    if (isMounted.current) {
      setState(value)
    }
  }

  return [state, setStateSafe]
}

export function useSafeStateOld(initialValue: any) {
  const isMounted = useRef(true)
  const [state, setState] = useState(initialValue)
  // @ts-ignore
  useEffect(() => () => (isMounted.current = false), [])

  const setStateSafe = useCallback((value) => {
    if (isMounted.current) {
      setState(value)
    }
    //console.log(isMounted.current)
  }, [])

  return [state, setStateSafe]
}
