import Model from '../Model'

class PermissionModel extends Model {
  private prefix = 'instruments/permissions'

  getWithParams(path: any, params: any) {
    path = this.prefix + path
    return this.withParams(path, params)
  }

  getAll(params: any) {
    const path = this.prefix
    return this.withParams(path, params)
  }

  add(data: {}) {
    return this.post(this.prefix + `/save-add`, data)
  }

  find(id: bigint) {
    return this.get(this.prefix + `/find/${id}`)
  }

  update(id: bigint, data: {}) {
    return this.post(this.prefix + `/save-edit/${id}`, data)
  }

  delete(id: bigint) {
    return this.get(this.prefix + `/delete/${id}`)
  }

  getCurrentUserAccess() {
    const path = this.prefix + '/current-user'
    return this.withParams(path, {})
  }

  getUserAccess(userId: any, departmentId: any) {
    const path = this.prefix + '/user/' + userId + '/departement/' + departmentId
    return this.withParams(path, {})
  }

  save(data: {}) {
    return this.post(this.prefix + `/save`, data)
  }
}

export default PermissionModel
