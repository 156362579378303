import {useCallback, useEffect, useState} from 'react'
import MKPagination from './MkPaginate'
import {shallowEqual, useSelector} from 'react-redux'
import './table.css'
import {useWhatChanged} from '@simbathesailor/use-what-changed'
import {currentTime} from '../../app/Utilities'
import {ExportToExcelTable} from '../Excel/ExportToExcel'

const serializeQuery = (params, prefix) => {
  const query = Object.keys(params).map((key) => {
    const value = params[key]

    if (params.constructor === Array) key = `${prefix}[]`
    else if (params.constructor === Object) key = prefix ? `${prefix}[${key}]` : key

    if (typeof value === 'object') return serializeQuery(value, key)
    else return `${key}=${encodeURIComponent(value)}`
  })

  return [].concat.apply([], query).join('&')
}

const initRows = null

const SORT_ASC = 'asc'
const SORT_DESC = 'desc'

const DataTable = (props) => {
  const cdi = useSelector((state) => state.appReducer.cdi, shallowEqual)
  const dataModel = props.dataModel
  const columns = props.columns
  const path = props?.path || ''

  const defaultParams = props?.defaultParams || {}

  const hasExportButton = props?.hasExportButton

  const tableReload = props.tableReload

  const tableClassName = props.className
  const [loading, setLoading] = useState(true)
  const [queryParams, setQueryParams] = useState({
    page: 1,
    per_page: 10,
    sort: {
      order: SORT_DESC,
      field: '',
    },
  })
  const [rows, setRows] = useState(initRows)
  const [pagination, setPagination] = useState({
    maxPages: 5,
    currentSort: {sort: null, field: null},
  })

  const fetchData = async (queryParams) => {
    const params = {...queryParams, ...defaultParams}
    try {
      //console.log(path);
      const {data} = await dataModel.getWithParams(path, params)

      if (data.type === 'success') {
        return {
          data: Object.values(data.result.data),
          totalItems: data.result.total,
          pageSize:
            data.result.per_page > data.result.total ? data.result.total : data.result.per_page,
        }
        // setRows(prevState => Object.values(data.result.data))
        // //console.log(Object.values(data.result.data))
        // setPagination(prevState => {
        //     return {
        //         ...pagination,
        //         totalItems: data.result.total,
        //         pageSize: (data.result.per_page > data.result.total) ? data.result.total : data.result.per_page
        //     }
        // })
      }
    } catch (e) {
      // setRows(prevState => initRows)
      return {
        data: initRows,
        totalItems: 0,
        pageSize: 0,
      }
    }
  }

  const searchInput = (column) => {
    let columnSearch = column?.search || null
    if (!columnSearch) return null

    let searchInputType = columnSearch?.type
    let searchInputName = 'search[' + column.dataField + ']'
    let operator = columnSearch?.operator ? columnSearch?.operator : 'like'
    let is_render = columnSearch?.is_render ? columnSearch?.is_render : false

    switch (searchInputType) {
      case 'select':
        return (
          <select
            name={searchInputName}
            data-operator={operator}
            data-is_render={is_render}
            placeholder={column.text}
            style={{
              padding: '5px',
              fontSize: '12px',
              borderRadius: '0',
            }}
            className='form-control'
            onChange={(e) => searchInputOnChange(e)}
          >
            <option key={'mk-table-search-select_option'} value=''></option>
            {columnSearch?.data?.length > 0 &&
              columnSearch?.data.map((option, index) => {
                return (
                  <option key={'mk-table-search-select_option-' + index} value={option.value}>
                    {option?.text || option?.label}
                  </option>
                )
              })}
          </select>
        )

      case 'date':
        return (
          <input
            type='date'
            placeholder={column.text}
            data-operator={operator}
            data-is_render={is_render}
            name={searchInputName}
            style={{
              padding: '5px',
              fontSize: '12px',
              borderRadius: '0',
            }}
            onChange={(e) => searchInputOnChange(e)}
            className='form-control'
          />
        )

      default:
        return (
          <input
            placeholder={column.text}
            data-operator={operator}
            data-is_render={is_render}
            name={searchInputName}
            style={{
              padding: '5px',
              fontSize: '12px',
              borderRadius: '0',
            }}
            onChange={(e) => searchInputOnChange(e)}
            className='form-control'
          />
        )
    }
  }

  const changeSort = (columnField) => {
    const params = {...queryParams}
    let sortField = queryParams?.sort?.field
    let sortOrder = queryParams?.sort?.order
    if (columnField === sortField) {
      sortOrder === SORT_ASC ? (params.sort.order = SORT_DESC) : (params.sort.order = SORT_ASC)
    } else {
      params.sort.field = columnField
      params.sort.order = SORT_ASC
    }
    params['page'] = 1
    setQueryParams((prevState) => params)
    setPagination((prevState) => {
      return {
        ...pagination,
        currentSort: {
          order: sortOrder,
          field: columnField,
        },
      }
    })
  }

  const searchInputOnChange = (e) => {
    const {name, value} = e.target
    const params = {...queryParams}
    const operator = e.target.attributes.getNamedItem('data-operator').value
    const is_render = e.target.attributes.getNamedItem('data-is_render').value
    params[name] = {
      value: value,
      operator: operator,
      is_render: is_render,
    }
    if (value === '') delete params[name]

    params['page'] = 1
    setQueryParams((prevState) => params)
  }

  const onChangePage = (page) => {
    const params = {...queryParams}
    params['page'] = page
    setQueryParams((prevState) => params)
  }

  const onChangePageSize = (pageSize) => {
    const params = {...queryParams}
    params['page'] = 1
    params['per_page'] = pageSize
    setQueryParams((prevState) => params)
  }

  useWhatChanged([queryParams, cdi, tableReload])

  const filename = '' + currentTime()
  const exportToExcel = () => {
    ;(async () => {
      await ExportToExcelTable(filename, dataModel, '', columns, {...queryParams, per_page: 'all'})
    })()
  }

  useEffect(() => {
    let isCancelled = false
    let f_t = new Date().getTime()
    // setLoading(true)
    fetchData(queryParams).then((response) => {
      console.log(new Date().getTime() - f_t)
      if (!isCancelled) {
        setRows((prevState) => response.data)
        setPagination((prevState) => {
          return {
            ...pagination,
            totalItems: response.totalItems,
            pageSize: response.pageSize,
          }
        })
        setLoading(false)
      }
    })
    return () => {
      isCancelled = true
    }
  }, [queryParams, cdi, tableReload])

  return (
    <>
      {hasExportButton && (
        <div className='d-flex justify-content-end mb-4'>
          <a
            href={void 0}
            onClick={() => exportToExcel()}
            className='btn btn-sm btn-light-success ms-2'
          >
            <i className='fas fa-file-excel'></i> Exporter
          </a>
        </div>
      )}
      <table className={tableClassName + ' table table-bordered'}>
        <thead>
          <tr className='fw-bolder text-muted'>
            {columns.map((column, index) => {
              let className = column?.headerClasses || ''
              let headerStyle = {}
              if (column.width) headerStyle['width'] = column.width
              if (column.align) headerStyle['align'] = column.align
              let style = {...(column?.headerStyle || {}), ...headerStyle}
              let canSort = column?.sort || null
              let sortField = queryParams?.sort?.field
              let sortOrder = queryParams?.sort?.order
              return (
                <th
                  key={'mk-table-thead-th' + index}
                  className={className}
                  style={style}
                  onClick={(e) => (canSort ? changeSort(column?.dataField) : void 0)}
                >
                  {column.text}
                  {canSort && column?.dataField === sortField ? (
                    <span>
                      {sortOrder === SORT_ASC ? (
                        <i className='ms-1 fa fa-arrow-up' aria-hidden='true'></i>
                      ) : (
                        <i className='ms-1 fa fa-arrow-down' aria-hidden='true'></i>
                      )}
                    </span>
                  ) : null}
                </th>
              )
            })}
          </tr>
          <tr>
            {columns.map((column, index) => {
              return (
                <th key={'mk-table-thead-search-th' + index} style={{padding: '5px'}}>
                  {searchInput(column)}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {!loading ? (
            rows?.length ? (
              rows.map((row, rowIndex) => {
                return (
                  <tr key={'mk-table-tbody-tr' + rowIndex}>
                    {columns.map((column, index) => {
                      let className = column?.className || ''
                      return (
                        <td key={'mk-table-thead-th' + index} className={className}>
                          {column.render
                            ? column.render(row, rowIndex)
                            : column.formatter
                            ? column.formatter(null, row, rowIndex, column.formatExtraData)
                            : row[column.dataField]}
                        </td>
                      )
                    })}
                  </tr>
                )
              })
            ) : (
              <tr>
                <td colSpan={columns.length} style={{textAlign: 'center'}}>
                  Aucune donnée disponible
                </td>
              </tr>
            )
          ) : (
            <tr>
              <td colSpan={columns.length} style={{textAlign: 'center'}}>
                Chargement en cours...
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {pagination?.totalItems > 0 && (
        <MKPagination
          pagination={pagination}
          onChangePage={onChangePage}
          onChangePageSize={onChangePageSize}
        />
      )}
    </>
  )
}

export default DataTable
