/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import DashBoardModel from '../../../../../models/gc/DashBoardModel'
import {formatNumberWithoutZero, getMonthName, LoadingSpinner} from '../../../../Utilities'

type Props = {
  className: string
  selectedYear: number
  selectedMonth: number
  selectedDay: number
}

type TypeOrders = {
  [label: string]: any
}

const LastOrdersActivitiesOnThisDay: React.FC<Props> = (props) => {
  const {className, selectedYear, selectedMonth, selectedDay} = props
  const [loading, setLoading] = useState(true)
  const [ordersActivities, setOrdersActivities] = useState<Array<TypeOrders>>([])

  const fetchOrdersLastActivities = async (year: number, month: number, day: number) => {
    setLoading(true)
    let dataModel = new DashBoardModel()
    const {data} = await dataModel.getSectionOrdersLastActivities(year, month, day)
    if (data.type === 'success') {
      setOrdersActivities((prevState) => data.result)
    } else {
      setOrdersActivities((prevState) => [])
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchOrdersLastActivities(selectedYear, selectedMonth, selectedDay)
  }, [selectedYear, selectedMonth, selectedDay])

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header align-items-center border-0 mt-4'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bolder fs-2 text-dark'>
            {`Activités des commandes de la journée du `}
            {`(${formatNumberWithoutZero(selectedDay)} ${getMonthName(
              selectedMonth
            )} ${selectedYear})`}
          </span>
        </h3>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body pt-5'>
        {/* begin::Timeline */}
        {loading ? (
          <LoadingSpinner parentClass='pt-5' iconClass='fs-1' />
        ) : (
          <div className='timeline-label'>
            {ordersActivities.length > 0 ? (
              ordersActivities.map((ordersActivity, ordersActivityIndex) => {
                return (
                  <div key={'_ordersActivity_' + ordersActivityIndex} className='timeline-item'>
                    <div className='timeline-label fw-bolder text-gray-800 fs-8'>
                      {ordersActivity?.hour_created_at}
                    </div>

                    <div className='timeline-badge'>
                      <i className='fa fa-genderless text-primary fs-1'></i>
                    </div>

                    <div className='fw-mormal timeline-content text-muted fs-8'>
                      <span className='fw-bolder text-gray-800 ps-2 fs-8'>
                        {ordersActivity?.num_bon}
                      </span>

                      <span className='fw-bolder ps-2 fs-8'>
                        <a href={void 0} className='text-danger'>
                          ({ordersActivity?.all_qty})
                        </a>
                      </span>

                      <span className='fw-bolder ps-2 fs-8'>-</span>

                      <span className='fw-bolder ps-2 fs-8'>
                        {ordersActivity?.gc_client_nom_client}{' '}
                        {'[' + ordersActivity?.gc_client_ville_client + ']'}
                      </span>
                    </div>
                  </div>
                )
              })
            ) : (
              <>
                <p className='fw-bolder text-muted text-center'>Aucune activité éffectuée ...</p>
              </>
            )}
          </div>
        )}
        {/* end::Timeline */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {LastOrdersActivitiesOnThisDay}
