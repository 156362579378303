/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../setup/redux/Store'
import {PageTitle} from '../../../_metronic/layout/core'
import {DashboardFilter} from './DashboardFilter'
import {DashboardView} from './DashboardView'

const DashboardBusiness: FC = () => {
  const cdi = useSelector((state: RootState) => state.appReducer.cdi, shallowEqual)
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1)
  const [selectedDay, setSelectedDay] = useState(new Date().getDate())

  // const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
  //   onYearChange(parseInt(event.target.value))
  // }

  const handleYearChange = (year: number) => {
    setSelectedYear(year)
  }

  const handleMonthChange = (month: number) => {
    setSelectedMonth(month)
  }

  const handleDayChange = (day: number) => {
    setSelectedDay(day)
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>Tableau de bord</PageTitle>

      <DashboardFilter
        startYear={2015}
        selectedYear={selectedYear}
        onYearChange={handleYearChange}
        selectedMonth={selectedMonth}
        onMonthChange={handleMonthChange}
        selectedDay={selectedDay}
        onDayChange={handleDayChange}
      />

      <DashboardView
        cdi={cdi}
        selectedYear={selectedYear}
        selectedMonth={selectedMonth}
        selectedDay={selectedDay}
      />
    </>
  )
}

export {DashboardBusiness}
